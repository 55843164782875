import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";

import { UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { PiUserThin } from "react-icons/pi";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import getCustomerByAuth from "../graphql/queries/GetCustomerByAuth";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => {
  return (
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  );
};

const Navbar = () => {
  const { userId } = useAuth();
  const { data } = useQuery(getCustomerByAuth, {
    variables: {
      id: userId,
    },
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    if (userId === null) {
      navigate("/login");
    }
  }, [userId, navigate]);
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      <NavButton
        title="Menu"
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={<AiOutlineMenu />}
      />
      <div className="flex">
        <div
          className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray dark:hover:bg-secondary-dark-bg rounded-lg group"
          onClick={() => handleClick("userProfile")}
        >
          <div className="p-2 rounded-full border">
            <PiUserThin className="w-5 h-5 group-hover:dark:text-white dark:text-white text-gray-400" />
          </div>
          <p>
            <span className="text-gray-400 text-14">Hi,</span>{" "}
            <span className="text-gray-400 font-bold ml-1 text-14">
              {data?.customers_by_pk?.username}
            </span>
          </p>
          <MdKeyboardArrowDown className="text-gray-400 text-14" />
        </div>
        {isClicked.userProfile && (
          <UserProfile userData={data?.customers_by_pk} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
