export const transformData = (rawData) => {
  const headers = rawData[1].slice(1);
  const newData = [];
  for (let rowIndex = 2; rowIndex < rawData.length; rowIndex++) {
    const row = rawData[rowIndex];
    const rowData = {};

    for (let colIndex = 1; colIndex < row.length; colIndex++) {
      const header = headers[colIndex - 1];
      const cellValue = row[colIndex];

      if (cellValue !== "empty") {
        rowData[header] = cellValue;
      }
    }

    newData.push(rowData);
  }

  return newData;
};
