export const validateMessage = ({ messageLength, is_burmese }) => {
  if (!is_burmese) {
    if (messageLength <= 160) {
      return 1;
    } else if (messageLength > 160 && messageLength < 306) {
      return 2;
    } else if (messageLength > 306 && messageLength < 459) {
      return 3;
    } else if (messageLength > 459 && messageLength < 612) {
      return 4;
    } else if (messageLength > 612 && messageLength < 765) {
      return 5;
    } else if (messageLength > 765) {
      return 5;
    } else {
      return null;
    }
  } else {
    if (messageLength <= 70) {
      return 1;
    } else if (messageLength > 70 && messageLength < 134) {
      return 2;
    } else if (messageLength > 134 && messageLength < 201) {
      return 3;
    } else if (messageLength > 201 && messageLength < 268) {
      return 4;
    } else if (messageLength > 268 && messageLength < 335) {
      return 5;
    } else if (messageLength > 335) {
      return 5;
    } else {
      return null;
    }
  }
};
