import { gql } from "@apollo/client";

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $new_password: String!
    $old_password: String!
    $phone: String!
  ) {
    changePassword(
      new_password: $new_password
      old_password: $old_password
      phone: $phone
    ) {
      message
      statusCode
      success
      data {
        available_sms
        email
        id
        phone
        sms_package
        used_sms
        username
      }
    }
  }
`;
