import { ChangePassword, Dashboard, TransactionReports } from "../pages";
import MainLayout from "../layout/MainLayout";

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/transaction-reports",
      element: <TransactionReports />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
  ],
};

export default MainRoutes;
