import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { SubmitButton, TextField } from "../components";
import { useForm } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import getCustomerByAuth from "../graphql/queries/GetCustomerByAuth";
import { CHANGE_PASSWORD } from "../graphql/mutations/ChangePassword";

const ChangePassword = () => {
  const { currentColor } = useStateContext();
  const { userId } = useAuth();
  const { data } = useQuery(getCustomerByAuth, {
    variables: {
      id: userId,
    },
  });

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      old_password: "",
      new_password: "",
    },
  });
  const onSubmit = async ({ old_password, new_password }) => {
    try {
      changePassword({
        variables: {
          old_password,
          new_password,
          phone: data?.customers_by_pk?.phone,
        },
        onCompleted: ({ changePassword }) => {
          if (changePassword.success) {
            toast.success("Password Changed Successfully!");
          }
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl">
      <div className="flex flex-col justify-center items-center">
        <div className="mt-10 p-8 rounded-lg sm:w-[500px] border w-full bg-white dark:bg-secondary-dark-bg">
          <h2 className="text-3xl font-semibold text-gray-400 text-center mb-6">
            Change Password
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">Old Password</h4>
                  <TextField
                    {...register("old_password", {
                      required: "old_password is required field",
                    })}
                    disabled={loading}
                    inputSize={"medium"}
                    fullWidth
                    placeholder={"Old Password"}
                    type="password"
                    error={
                      touchedFields.old_password &&
                      errors.old_password &&
                      Boolean(errors.old_password)
                    }
                    helperText={
                      touchedFields.old_password &&
                      errors.old_password &&
                      errors.old_password.message
                    }
                  />
                </div>
              </div>
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">New Password</h4>
                  <TextField
                    {...register("new_password", {
                      required: "new_password is required field",
                    })}
                    disabled={loading}
                    inputSize={"medium"}
                    fullWidth
                    placeholder={"New Password"}
                    type="password"
                    error={
                      touchedFields.new_password &&
                      errors.new_password &&
                      Boolean(errors.new_password)
                    }
                    helperText={
                      touchedFields.new_password &&
                      errors.new_password &&
                      errors.new_password.message
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <SubmitButton
                type="submit"
                disabled={loading || !(isValid && isDirty)}
                text={loading ? "Creating..." : "Save"}
                borderRadius={8}
                bgColor={currentColor}
                color="#fff"
                width={"full"}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
