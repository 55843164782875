import { gql } from "@apollo/client";

const getCustomerByAuth = gql`
  query MyQuery($id: uuid!) {
    customers_by_pk(id: $id) {
      email
      id
      created_at
      disabled
      phone
      status
      updated_at
      username
      email
    }
  }
`;

export default getCustomerByAuth;
